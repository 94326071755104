/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LibraryModule as WidgetBar } from 'oc-widget-top-module-client'
import { triggerWidget, closeWidgets } from '../../../redux/actions/widgetLayoutAction'
import { ContactRecord, iBannerInfo } from '../../ContactCenter/Models'
import ContactCenter from '../../../Apps/ContactCenter/Modules/ContactCenter'
import { setAutoTranslation } from '../../../redux/actions/contactCenterActions'
import localization from './lang/localization'
import '../styles.scss'
import 'oc-widget-top-module-client/dist/index.css'
import { CALLEVENTS } from '../../ContactCenter/Constants'
import Utility from '../../ContactCenter/Modules/Utility'
import { useContextData } from '../Hooks'
import { checkAdobeAnswerEligibility } from '../../../redux/actions/adobeAnswerActions'
import { isZendeskDecommissioned } from '../../../utils/utils'
import { RootState } from '../../../redux/reducers/rootReducer'

const TopBar = () => {
  const dispatch = useDispatch()
  const { widgets, widgetWrapperWidth, widgetStack } = useSelector((state: RootState) => state.widgetLayout)
  const { currentConversation, currentConversationAC, currentView, autoTranslation = {}, bannerInfo, adobeAnswersEligible, showAdobeAnswers: showAdobeAnswersReduxState } = useSelector((state: RootState) => state.contactCenter)
  const conversation = currentView === 'AC_VIEW' ? currentConversationAC : currentConversation
  const { darkMode, language } = useSelector((state: RootState) => state.preferences)
  const [userWidgets, setUserWidgets] = useState<any[]>([])
  const {
    ocPlatformData: {
      chatInfo: { newCustomer: newCustomerInfo = '', initialContactId = '', currentContactId = '', status = '', duration = '', updateDate: updateDateInfo = '' } = {},
      callInfo: { currentContactId: callContactId = '', status: callStatus = '', origin: callOrigin = '', isMute = false, linkedConversationId: callLinkedConversationId = '' } = {}
    } = {},
    jcAuthData: {
      queueName: { value: queueNameVal = '' } = {},
      currentQueue: { value: currentQueueVal = '' } = {},
      language: { value: customerLang = '' } = {},
      origin: { value: jcOrigin = '' } = {},
      channel: { value: channel = '' } = {},
      conversationStartDate: { value: conversationStartDate = '' } = {},
      linkedConversationId: { value: jcLinkedConversationId = '' } = {}
    } = {}
  } = conversation
  const origin = callOrigin || jcOrigin || ''
  const {
    ocAppData: { originForCurrentConversation = '', closedConversation = '', closedPhoneCall = '' }
  } = useContextData()
  const phoneOrigin = ['outbound-phone', 'inbound-phone', 'bot-to-phone']
  const isPhoneOrigin = phoneOrigin.includes(originForCurrentConversation)
  const isActive = !(closedConversation && closedPhoneCall)
  const isShowAdobeAnswers = !isPhoneOrigin && isActive
  const showAdobeAnswers = showAdobeAnswersReduxState && adobeAnswersEligible

  useEffect(() => {
    if (isShowAdobeAnswers !== showAdobeAnswersReduxState) {
      dispatch({ type: 'SET_SHOW_ADOBE_ANSWERS', payload: isShowAdobeAnswers })
    }
  }, [isShowAdobeAnswers, showAdobeAnswersReduxState])

  useEffect(() => {
    if (isShowAdobeAnswers) {
      dispatch(checkAdobeAnswerEligibility(currentQueueVal))
    }
  }, [currentContactId, currentQueueVal, isShowAdobeAnswers])

  useEffect(() => {
    if (conversation?.jcAuthData) {
      const widgetPriority = {
        'SLS-s': ['AA', 'SC'],
        'SLS-m': ['AA', 'CH', 'SC'],
        'SLS-l': ['AA', 'CH', 'SC'],
        'CI-xs': ['CM', 'CI'],
        'CI-s': ['CM', 'CH', 'CI'],
        'CI-m': ['CM', 'AA', 'CI'],
        'CI-l': ['AA', 'CM', 'CH', 'CI'],
        'ENT-xs': ['ECM', 'CI'],
        'ENT-s': ['ECM', 'CH', 'CI'],
        'ENT-m': ['ECM', 'AA', 'CI'],
        'ENT-l': ['AA', 'ECM', 'CH', 'CI']
      }
      if (!showAdobeAnswers) Object.keys(widgetPriority).map((key) => (widgetPriority[key] = widgetPriority[key].filter((w) => w !== 'AA')))

      const widgetSelection = JSON.parse(sessionStorage.getItem('widgetSelection') || '{}')[initialContactId] ?? []
      const isWidgetSelection = widgetSelection.length
      const queue = currentQueueVal
      const element = queue ? queue.substring(0, 4) : ''

      if (isWidgetSelection) {
        let widgetSelectionRemoveAA = widgetSelection
        if (!showAdobeAnswers) {
          widgetSelectionRemoveAA = widgetSelectionRemoveAA.filter((w) => w !== 'AA')
        }
        if (element === 'SLS-') {
          widgetSelectionRemoveAA = widgetSelectionRemoveAA.filter((w) => w !== 'CI' && w !== 'ECM' && w !== 'CM')
        } else {
          widgetSelectionRemoveAA = widgetSelectionRemoveAA.filter((w) => w !== 'SC')
        }
        widgetSelectionRemoveAA.map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
      }

      if (currentQueueVal || queueNameVal) {
        if (element === 'SLS-') {
          if (!isWidgetSelection) {
            if (widgetWrapperWidth < 1115) {
              widgetPriority['SLS-s'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else if (widgetWrapperWidth < 1430) {
              widgetPriority['SLS-m'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else {
              widgetPriority['SLS-l'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            }
          }
          const widget = JSON.parse(JSON.stringify(widgets))
            .filter((w: any) => w.title === 'SC' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
            .sort((a: any, b: any) => {
              if (a.title === 'PDC') return -1
              if (b.title === 'AA') return -1
              if (b.title === 'CH') return -1
              if (b.title === 'SC') return 1
              return 0
            })
          handleSetUserWidgets(widget)
        } else if (element === 'ENT-' || (isZendeskDecommissioned() && element?.toLowerCase() === 'sign')) {
          if (!isWidgetSelection) {
            if (widgetWrapperWidth < 1210) {
              widgetPriority['ENT-xs'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else if (widgetWrapperWidth > 1210 && widgetWrapperWidth < 1425) {
              widgetPriority['ENT-s'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else if (widgetWrapperWidth > 1425 && widgetWrapperWidth < 1735) {
              widgetPriority['ENT-m'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else {
              widgetPriority['ENT-l'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            }
          }
          handleSetUserWidgets(
            JSON.parse(JSON.stringify(widgets))
              .filter((w: any) => w.title === 'CI' || w.title === 'ECM' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
              .sort((a: any, b: any) => {
                if (a.title === 'PDC') return -1
                if (b.title === 'AA') return -1
                if (b.title === 'CH') return -1
                if (b.title === 'ECM') return 0
                return 1
              })
          )
        } else if (element === 'DNL-') {
          if (!isWidgetSelection) {
            if(showAdobeAnswers) {
              dispatch(triggerWidget(widgets, 'AA', widgetWrapperWidth, widgetStack))
            }
            dispatch(triggerWidget(widgets, 'CH', widgetWrapperWidth, widgetStack))
            dispatch(triggerWidget(widgets, 'CI', widgetWrapperWidth, widgetStack))
          }
          handleSetUserWidgets(
            JSON.parse(JSON.stringify(widgets))
              .filter((w: any) => w.title === 'CI' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
              .sort((a: any, b: any) => {
                if (a.title === 'PDC') return -1
                if (b.title === 'PDC') return 1
                if (b.title === 'AA') return -1
                if (b.title === 'CH') return -1
                if (b.title === 'CI') return 0
                return 1
              })
          )
        } else {
          if (!isWidgetSelection) {
            if (widgetWrapperWidth < 1210) {
              widgetPriority['CI-xs'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else if (widgetWrapperWidth > 1210 && widgetWrapperWidth < 1425) {
              widgetPriority['CI-s'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else if (widgetWrapperWidth > 1425 && widgetWrapperWidth < 1735) {
              widgetPriority['CI-m'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else {
              widgetPriority['CI-l'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            }
          }
          handleSetUserWidgets(
            JSON.parse(JSON.stringify(widgets))
              .filter((w: any) => w.title === 'CI' || w.title === 'CM' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
              .sort((a: any, b: any) => {
                if (a.title === 'PDC') return -1
                if (b.title === 'AA') return -1
                if (b.title === 'CH') return -1
                if (b.title === 'CM') return 0
                return 1
              })
          )
        }
      } else {
        if (!isWidgetSelection) {
          if (widgetWrapperWidth < 1210) {
            widgetPriority['CI-xs'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
          } else if (widgetWrapperWidth > 1210 && widgetWrapperWidth < 1425) {
            widgetPriority['CI-s'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
          } else if (widgetWrapperWidth > 1425 && widgetWrapperWidth < 1735) {
            widgetPriority['CI-m'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
          } else {
            widgetPriority['CI-l'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
          }
        }
        handleSetUserWidgets(
          JSON.parse(JSON.stringify(widgets))
            .filter((w: any) => w.title === 'CI' || w.title === 'CM' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
            .sort((a: any, b: any) => {
              if (a.title === 'PDC') return -1
              if (b.title === 'AA') return -1
              if (b.title === 'CH') return -1
              if (b.title === 'CM') return 0
              return 1
            })
        )
      }
    }
    const cleanup = () => {
      dispatch(closeWidgets(widgets))
    }
    return cleanup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContactId, showAdobeAnswers])

  useEffect(() => {
    SetWidgets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgets])

  let SetWidgets = () => {
    if (conversation?.jcAuthData) {
      if (currentQueueVal || queueNameVal) {
        const queue = currentQueueVal
        const element = queue ? queue.substring(0, 4) : ''
        if (element === 'SLS-')
          handleSetUserWidgets(
            JSON.parse(JSON.stringify(widgets))
              .filter((w: any) => w.title === 'SC' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
              .sort((a: any, b: any) => {
                if (a.title === 'PDC') return -1
                if (b.title === 'CH') return -1
                if (b.title === 'AA') return -1
                if (b.title === 'SC') return 1
                return 0
              })
          )
        else if (element === 'ENT-' || (isZendeskDecommissioned() && element?.toLowerCase() === 'sign'))
          handleSetUserWidgets(
            JSON.parse(JSON.stringify(widgets))
              .filter((w: any) => w.title === 'CI' || w.title === 'ECM' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
              .sort((a: any, b: any) => {
                if (a.title === 'PDC') return -1
                if (b.title === 'AA') return -1
                if (b.title === 'CH') return -1
                if (b.title === 'ECM') return 0
                return 1
              })
          )
        else if (element === 'DNL-')
          handleSetUserWidgets(
            JSON.parse(JSON.stringify(widgets))
              .filter((w: any) => w.title === 'CI' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
              .sort((a: any, b: any) => {
                if (a.title === 'PDC') return -1
                if (b.title === 'CH') return -1
                if (b.title === 'AA') return -1
                if (b.title === 'CI') return 0
                return 1
              })
          )
        else
          handleSetUserWidgets(
            JSON.parse(JSON.stringify(widgets))
              .filter((w: any) => w.title === 'CI' || w.title === 'CM' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
              .sort((a: any, b: any) => {
                if (a.title === 'PDC') return -1
                if (b.title === 'AA') return -1
                if (b.title === 'CH') return -1
                if (b.title === 'CM') return 0
              })
          )
      } else {
        handleSetUserWidgets(
          JSON.parse(JSON.stringify(widgets))
            .filter((w: any) => w.title === 'CI' || w.title === 'CM' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
            .sort((a: any, b: any) => {
              if (a.title === 'PDC') return -1
              if (b.title === 'AA') return -1
              if (b.title === 'CH') return -1
              if (b.title === 'CM') return 0
            })
        )
      }
    }
  }

  const handleSetUserWidgets = (widgets: any[]) => {
    const pdcWidgetHideForCallOrigin = [CALLEVENTS.INBOUND_PHONE, CALLEVENTS.OUTBOUND_PHONE, CALLEVENTS.BOT_TO_PHONE]
    if (pdcWidgetHideForCallOrigin.includes(origin)) {
      const widget = widgets?.filter((w: any) => w.title !== 'PDC') || []
      setUserWidgets(widget)
    } else setUserWidgets(widgets)
  }

  function callBackEventListener(eventType: string, eventData: any): void {
    if (eventType === 'autoTranslation') {
      dispatch(setAutoTranslation(eventData))
    } else if (eventType !== 'EXCEPTION') {
      dispatch(triggerWidget(widgets, eventData.title, widgetWrapperWidth, widgetStack))
      SetWidgets()
      // load the existing widget Selection values
      let persistentWidget = JSON.parse(sessionStorage.getItem('widgetSelection') || '{}')
      // Add the opened widget
      const openWidget = JSON.parse(JSON.stringify(widgets))
        .filter((w) => w.active)
        .map((w) => w.title)
      persistentWidget[initialContactId] = openWidget ?? []
      // update stored values
      sessionStorage.setItem('widgetSelection', JSON.stringify(persistentWidget))
    }
  }

  try {
    const defaultHeader = localization[language].TOP_BAR.MY_CONVERSATIONS
    const newCustomer = newCustomerInfo ? 1 : 0
    const convStatus = status || ''
    const convDuration = duration || 0
    const chartStartTime = conversationStartDate
    const updateDate = updateDateInfo || new Date()
    const customerName = ContactCenter.getCustomerName(conversation, defaultHeader)
    const octDuration = Math.round((new Date(updateDate).getTime() - new Date(chartStartTime).getTime()) / 1000) || 0
    const isAcw = (bannerInfo?.isAcw && (bannerInfo?.contactId === currentContactId || bannerInfo?.contactId === callContactId)) || false
    const linkedConversationId = callLinkedConversationId || jcLinkedConversationId || currentContactId
    const contactId = origin === CALLEVENTS.CHAT_TO_PHONE ? linkedConversationId : callContactId || currentContactId
    const timer: any = Utility.getOctAndThisAgentTimer({
      contactId,
      convStatus,
      callStatus,
      origin,
      convDuration,
      octDuration,
      chartStartTime,
      channel,
      updateDate,
      isAcw
    })
    let octTime: number = timer.octTime || 0,
      agentTime: number = timer.agentTime || 0
    const muteTime = callStatus === 'Active' && bannerInfo?.muteStartDate ? Math.round((new Date().getTime() - new Date(bannerInfo.muteStartDate).getTime()) / 1000) || 0 : 0

    const json = {
      widgets: currentContactId ? userWidgets : [],
      lang: language,
      customerLang,
      widgetHeader: customerName,
      darkMode,
      transferCount: newCustomer,
      duration: Number(octTime) || 0,
      status: convStatus,
      currentView,
      selectedContactId: currentContactId,
      agentTime: Number(agentTime) || 0,
      callContactId,
      callStatus,
      autoTranslation,
      origin,
      isAcw,
      muteTime: Number(muteTime) || 0,
      isMute,
      channel
    }
    return (
      <div className='chat-topbar'>
        <WidgetBar contextData={json} callBackEvent={callBackEventListener} />
      </div>
    )
  } catch (error) {
    console.log(error)
    return <></>
  }
}

export default TopBar
