/* eslint-disable import/no-cycle */
import store from '../redux/store';
import { TrackableEvent } from 'oc-analytics';
import { defineEvent } from '../analytics';
import analyticUser from './analytics-user';
import { getCurrentConvID, getCurrentQueue } from '../utils/conversation-utils'
import { updateCTRForConversationSummaryAnalytics } from './ctr-updates';

declare const window: any;
const eventArray: Array<TrackableEvent> = [];
const EVENTS_LIMIT = 5;

/**
 * Send all events in the eventArray
 */
export function sendAllEvents(): void {

    eventArray.forEach(event => {
      event.data["event.guid"] = window.dunamis_analytics.user.ldap;
      event.data["event.user_guid"] = window.dunamis_analytics.user.email;
      event.data["event.session_guid"] = window.dunamis_analytics.user.ldap + "-" + event.data["event.session_guid"];
    })

    if ((window as any).dunamis_analytics.sendEvents && eventArray.length !== 0) {
        (window as any).dunamis_analytics.trackMany(eventArray);
        eventArray.length = 0;
    }
}

/**
 * send the analytics
 * @param events events can be single or array of analytic events
 */
export function sendTrackMetric(e: TrackableEvent): void {
    const event: TrackableEvent = e;

    eventArray.push(event);
    if (eventArray.length >= EVENTS_LIMIT) {
        sendAllEvents();
    }
}

export function sendChatPageRenderAnalytics(data: any = {}): void {
  const event = defineEvent({
    workflow: 'SUSI',
    subcategory: 'Main',
    type: 'render',
    subtype: 'agent-console',
    contentId: getCurrentConvID()
  })

  sendTrackMetric(event)
}

export function sendLoginAnalytics(data: any = {}): void {
  // Do not send login analytics on page refresh
  if (isPageRefreshed()) return
  // Set analytic session for user
  if (data.subCategory === 'Implicit' && data.type === 'success') analyticUser.setUserAnalyticsSession()

  const event = defineEvent({
    workflow: 'SUSI',
    subcategory: data.subCategory || 'Main',
    type: data.type || 'click',
    subtype: data.subType || 'signin'
  })
  sendTrackMetric(event)
}

export function sendLogoutAnalytics(data: any = {}) {
  const event = defineEvent({
    workflow: 'SUSI',
    subcategory: 'Implicit',
    type: data.type,
    subtype: 'signout',
    value: ''
  })

  sendTrackMetric(event)
  analyticUser.clearUserAnalyticsSession()
}

export function sendLogoutConfirmationAnalytics(data: any = {}) {
  const event = defineEvent({
    workflow: 'SUSI',
    subcategory: 'Agent Console',
    type: 'click',
    subtype: data.subType,
    value: ''
  })

  sendTrackMetric(event)
}

export function sendThemeAnalytics(data: any = {}) {
  console.log('theme: ', data)
  const event = defineEvent({
    workflow: 'Agent Profile',
    subcategory: 'Settings',
    type: 'click',
    subtype: 'theme',
    value: data.darkMode ? 'dark' : 'light'
  })

  sendTrackMetric(event)
}

export function sendSoundAnalytics(data: any = {}) {
  const event = defineEvent({
    workflow: 'Agent Profile',
    subcategory: 'Settings',
    type: 'click',
    subtype: 'sound',
    value: data.sound ? 'on' : 'off'
  })

  sendTrackMetric(event)
}

export function sendUserStatus(data: any = {}) {
  const event = defineEvent({
    workflow: 'Agent Profile',
    subcategory: 'Status',
    type: 'click',
    subtype: 'status',
    value: data.status
  })

  sendTrackMetric(event)
}

export function sendChatEvents(data: any = {}) {
  const { subCategory, subType, type, value, ...rest} = data;

  const event = defineEvent({
    workflow: 'Conversation',
    subcategory: subCategory || 'Chat',
    type: type || 'click',
    subtype: subType || 'chat',
    value: value,
    contentId: getCurrentConvID(),
    ...rest
  })

  sendTrackMetric(event)
}

export function sendOACWidgetToggleEvents(data: any = {}) {
  const event = defineEvent({
    workflow: !!data.workflow ? "Conversation" : 'Agent Console',
    subcategory: data.subCategory || 'Widget',
    type: data.type || 'render',
    subtype: data.subType || 'widget',
    value: data.value,
    contentId: getCurrentConvID()
  })

  sendTrackMetric(event)
}

export function sendAgentToolbarEvent(data: any = {}) {
  const { subCategory, subType, value, ...rest} = data;
  const event = defineEvent({
    workflow: 'Conversation',
    subcategory: subCategory || 'Agent Toolbar',
    type: 'click',
    subtype: subType,
    value: value,
    contentId: getCurrentConvID(),
    ...rest
  })

  sendTrackMetric(event)
}

export function sendFlyoutPanelEvent(data: any = {}) {
  const event = defineEvent({
    workflow: 'Conversation',
    subcategory: 'Chat',
    type: 'click',
    subtype: data.subType,
    value: data.value,
    contentId: getCurrentConvID()
  })

  sendTrackMetric(event)
}

export function sendNavbarEvent(data: any = {}) {
  const event = defineEvent({
    workflow: 'Conversation',
    subcategory: 'Chat',
    type: 'click',
    subtype: 'all-conversations',
    value: data.value,
    contentId: getCurrentConvID()
  })

  sendTrackMetric(event)
}

export function sendTransferChatEvent(data: any = {}) {
  const event = defineEvent({
    workflow: 'Conversation',
    subcategory: 'Transfer Chat',
    type: data.type || 'click',
    subtype: data.subType,
    value: data.value,
    errorDesc: data.error,
    contentId: getCurrentConvID()
  })

  sendTrackMetric(event)
}

export function sendPDCEvents(data: any = {}) {
  const { type, ...rest } = data;
  const event = defineEvent({
    workflow: 'Conversation',
    subcategory: 'Predefined Content',
    type: data.type || 'render',
    subtype: data.subType,
    contentId: getCurrentConvID(), // comming from notification
    ...rest
  })

  sendTrackMetric(event)
}

/**
 * ...rest may contain error desc, # of files attached, size, extention, mimetype
 */
export function sendTapToolEvents(data: any = {}) {
  const { type, subType, subCategory, ...rest } = data;
  const event = defineEvent({
    workflow: 'Conversation',
    subtype: subType,
    subcategory: subCategory,
    type: 'click', // we have a text field for the reason, then it wont be a text field
    contentId: getCurrentConvID(), // comming from notification,
    ...rest
  })

  sendTrackMetric(event)
}

/**
 * ...rest may contain error desc, # of files attached, size, extention, mimetype
 */
export function sendFileAttachmentEvents(data: any = {}) {
  const { type, subType, ...rest } = data;
  const event = defineEvent({
    workflow: 'Conversation',
    subcategory: 'File',
    type: type || 'click',
    subtype: subType || 'attachment',
    contentId: getCurrentConvID(), // comming from notification,
    ...rest
  })

  sendTrackMetric(event)
}

/**
 * ...rest may contain error desc, # of files attached, size, extention, mimetype
 * 
 * This method contains an event which is used to show whether the copied adobe answers in CCP
 * is edited by agent or not
 */
export function sendGenAIAdobeAnswersEvents(data: any = {}) {
  const { type, subType, subCategory, ...rest } = data;
  const convId  = getCurrentConvID();

  const event = defineEvent({
    workflow: 'Adobe Answers',
    subcategory: subCategory || 'Search',
    type: type || 'click',
    subtype: subType,
    contentId: convId, // comming from notification,
    'content.type': getCurrentQueue(),
    ...rest
  })

  sendTrackMetric(event);
}

/**
 * This function return true if page is refreshed or not based on the localstorage session item
 * If it is new page load/logout, 'analytic_session' wont exists in the localstorage
 * @returns boolean
 */
function isPageRefreshed(): boolean {
  const isSessionExists = !!localStorage.getItem('analytic_session') || false
  return isSessionExists
}

export function exportAnalyticMethodsToWindow() {
  window.dunamis_analytics = window.dunamis_analytics || {};
  window.dunamis_analytics.sendPDCEvents = sendPDCEvents;
  window.dunamis_analytics.sendChatEvents = sendChatEvents;
  window.dunamis_analytics.sendToolbarEvent = sendAgentToolbarEvent;
  window.dunamis_analytics.sendTCPEvent = sendTransferChatEvent;
  window.dunamis_analytics.sendWidgetToggleEvents = sendOACWidgetToggleEvents;
  window.dunamis_analytics.sendFlyoutPanelEvent = sendFlyoutPanelEvent;
  window.dunamis_analytics.sendNavbarEvent = sendNavbarEvent;
  window.dunamis_analytics.sendLogoutConfirmationAnalytics = sendLogoutConfirmationAnalytics;
  window.dunamis_analytics.defineDunamisEvent = defineEvent;
  window.dunamis_analytics.sendTapToolEvents = sendTapToolEvents;
  window.dunamis_analytics.sendFileAttachmentEvents = sendFileAttachmentEvents;
  window.dunamis_analytics.sendGenAIAdobeAnswersEvents = sendGenAIAdobeAnswersEvents;
  window.updateCTRForConversationSummaryAnalytics = updateCTRForConversationSummaryAnalytics;
}
