/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable array-callback-return */
import {
  OPEN_WIDGETS,
  UDATE_WIDGETWIDTH,
  TRANSFER_COUNT
  // ,
  // SET_USER_WIDGETS
} from '../constants/widgetLayoutConstants'

export const triggerWidget = (widgets: any, title: string, widgetWrapperWidth: number, widgetStack: string[]) => (dispatch: any) => {
  console.log('activeWidget from action', widgets, title, widgetWrapperWidth)
  const extraMargin = 30 + 30
  let widthUsed = 0

  // eslint-disable-next-line
  widgets.map((widget: any) => {
    // Add widget to check if width is available
    if (widget.title === title) {
      widget.active = !widget.active
    }
    // calculate used width
    widthUsed = widget.active ? widthUsed + widget.width + 10 : widthUsed
    // Remove widget statue after checking
    if (widget.title === title) {
      widget.active = !widget.active
    }
  })

  console.log('WidgetItemsWrapper widthUsed first', widgets, widthUsed, widgetWrapperWidth)

  // check if width is available
  if (widthUsed + extraMargin < widgetWrapperWidth) {
    console.log('WidgetItemsWrapper widthUsed else if inner', widgets, widthUsed + extraMargin, widgetWrapperWidth)
    // eslint-disable-next-line
    widgets.map((widget: any) => {
      // Add widget if width is available
      if (widget.title === title && !widget.active) {
        widget.active = !widget.active
        widget.position = 1
      } else if (widget.title === title && widget.active) {
        widget.active = !widget.active
        widget.position = -1
      }
      if (widget.title !== title && widget.position === 1) {
        widget.position = 0
      } else if (widget.title !== title && widget.position === 0) {
        widget.position = -1
      }
    })
  } else {
    widgets.map((widget: any) => {
      // remove widget if width is available not available
      if (widget.title !== title && widget.position === 1) {
        widget.active = !widget.active
        // widget.position = 0;
      }
      if (widget.title === title) {
        widget.active = !widget.active
        // widget.position = 1;
      }
    })
    widthUsed = 0
    // eslint-disable-next-line
    widgets.map((widget: any) => {
      // calculate used width
      widthUsed = widget.active ? widthUsed + widget.width + 10 : widthUsed
    })
    if (widthUsed + extraMargin > widgetWrapperWidth) {
      widgets.map((widget: any) => {
        // remove widget if width is available not available
        if (widget.title !== title && widget.position === 0) {
          widget.active = !widget.active
          // widget.position = 0;
        }
      })
    }

    if (widthUsed + extraMargin < widgetWrapperWidth) {
      // eslint-disable-next-line
      widgets.map((widget: any) => {
        if (widget.title !== title && widget.position === 1) {
          widget.position = 0
        } else if (widget.title !== title && widget.position === 0) {
          widget.position = -1
        }
        if (widget.title === title) {
          widget.position = 1
        }
      })
      console.log('WidgetItemsWrapper widthUsed else', widgets, widthUsed + extraMargin, widgetWrapperWidth)
    } else {
      // eslint-disable-next-line
      widgets.map((widget: any) => {
        if (widget.title !== title && widget.position === 1) {
          // widget.active = !widget.active;
          widget.position = -1
        }
        if (widget.title !== title && widget.position === 0) {
          // widget.active = !widget.active;
          widget.position = -1
        }
        if (widget.title === title) {
          // widget.active = !widget.active;
          widget.position = 1
        }
      })
    }
  }
  return dispatch({ type: OPEN_WIDGETS, payload: widgets })
}

export const closeWidgets = (widgets: any) => (dispatch: any) => {
  console.log(
    'close Widget',
    widgets,
    [...widgets].map((a) => {
      a.active = false
      a.position = -1
      return a
    })
  )
  return dispatch({
    type: OPEN_WIDGETS,
    payload: [...widgets].map((a) => {
      a.active = false
      a.position = -1
      return a
    })
  })
}
export const handleScreenResize = (widgets: any, widgetStack: any, widgetWrapperWidth: any) => (dispatch: any) => {
  console.log('handleScreenResize from action', widgets, widgetStack, widgetWrapperWidth)
  const extraMargin = 30 + 35

  let widthUsed = 0
  let lastActiveWidget = ''
  // eslint-disable-next-line
  widgets.map((widget: any) => {
    // calculate used width
    widthUsed = widget.active ? widthUsed + widget.width + 10 : widthUsed
    lastActiveWidget = widget.active ? widget.title : lastActiveWidget
  })
  console.log('handleScreenResize from action', widgetWrapperWidth, widthUsed, lastActiveWidget)
  if (widthUsed + extraMargin > widgetWrapperWidth && lastActiveWidget !== '') {
    dispatch({
      type: OPEN_WIDGETS,
      payload: [...widgets].map((a) => {
        if (a.title === lastActiveWidget) {
          a.active = false
          a.position = -1
        }
        return a
      })
    })
  }

  return dispatch({ type: UDATE_WIDGETWIDTH, payload: widgetWrapperWidth })
}

export const triggerMultipleWidget = (newWidgets: any) => (dispatch: any) => {
  dispatch({ type: OPEN_WIDGETS, payload: newWidgets })
}

export const triggerTransferCount = (transferCount: number) => (dispatch: any) => {
  dispatch({ type: TRANSFER_COUNT, payload: transferCount })
}

// export const setUserWidgets = (conversation: any) => (dispatch: any) => {
//   dispatch({ type: SET_USER_WIDGETS, payload: conversation });
// }
