import { getFlagsForConversationSummary } from '../Apps/ContactCenter/Api/api'

export const ENABLE_SUMMARY = 'EnableSummary'

export async function enableConvSummaryByQueue(queue) {
    const floodgateResponse: any = await getFlagsForConversationSummary(queue) || {};
    const featureFlags = floodgateResponse?.data?.releases[0]?.features || []
    const isAllowedFromFG = featureFlags?.includes(ENABLE_SUMMARY)
    
    console.log('Conversation Summary Enabled By Queue:', isAllowedFromFG)

    return isAllowedFromFG || false;
}