import store from '../redux/store';
import { fetchActiveAndCurrentConversationData, getCurrentConvID } from '../utils/conversation-utils'
import { updateContactAttributes } from '../Apps/ContactCenter/Api/api';

/**
 * @param contactId 
 * @param summary 
 * @param updateContactAttributes 
 * 
 * Sample format:
 * =============
 *  "TRANSFER_LEG_ORDER": "CONTACTID_1; CONTACTID_2; CONTACTID_3",
 *  "SUMMARY_ELIGIBLE": "CONTACTID_1: Yes; CONTACTID_2: Yes; CONTACTID_3: No",
 *  "SUMMARY_GENERATED": "CONTACTID_1: Yes; CONTACTID_2: Yes; CONTACTID_3: No",
 *  "SUMMARY_USEFUL": "CONTACTID_3: Yes; CONTACTID_1: No",
 */
export async function updateCTRForConversationSummaryAnalytics(contactId, summary = "", isAgentProvidedFeedback, thumps_up_down) {
    const attributes = {};
    const activeAndCurrentConversation = fetchActiveAndCurrentConversationData(contactId);
    const { jcAuthData } = activeAndCurrentConversation || {};
    // const { chatInfo: { queueName } } = ocPlatformData;
    const {
        TRANSFER_LEG_ORDER: transferLegOrder,
        SUMMARY_ELIGIBLE: summaryEligible,
        SUMMARY_GENERATED: summaryGenerated,
        SUMMARY_USEFUL: summaryUseful,
    } = jcAuthData || {};

    // Get the allowed queues from floodgate for eligibility of summary
    // const queue = currentQueue?.value || "";
    // const regex = new RegExp(queue, 'ig');
    // const queues = localStorage.getItem('conversationSummarySkills');
    // const isEligible = regex.test(queues);

    // if (!activeAndCurrentConversation && !isEligible) return;

    if (!activeAndCurrentConversation) return;

    // Check whether the conversation Id is already there in the each property ? if so don't update the record
    const isTransferLegOrderUpdated = transferLegOrder?.value?.includes(contactId) || false;
    const isSummaryEligibleFlagUpdated = summaryEligible?.value?.includes(contactId) || false;
    const isSummaryGeneratedFlagUpdated = summaryGenerated?.value?.includes(contactId) || false;

    if (!isTransferLegOrderUpdated) {
        attributes["TRANSFER_LEG_ORDER"] = transferLegOrder
            ? `${transferLegOrder.value}; ${contactId}`
            : contactId;
    }

    if (!isSummaryEligibleFlagUpdated) {
        attributes['SUMMARY_ELIGIBLE'] = summaryEligible
            ? (`${summaryEligible.value}; ${contactId}: ${!!summary}`)
            : (`${contactId}: ${!!summary}`);
    }

    if (!isSummaryGeneratedFlagUpdated) {
        attributes['SUMMARY_GENERATED'] = summaryGenerated
            ? (`${summaryGenerated.value}; ${contactId}: ${!!summary}`)
            : (`${contactId}: ${!!summary}`);
    }

    if (isAgentProvidedFeedback) {
        attributes['SUMMARY_USEFUL'] = summaryUseful
            ? (`${summaryUseful.value}; ${contactId}: ${thumps_up_down}`)
            : (`${contactId}: ${thumps_up_down}`);
    }

    try {
        await updateContactAttributes({
            attributes,
            contactId
        });
    } catch (e) {
        console.log("Failed to update CTR on updateCTRForConversationSummaryAnalytics: ", e);
    }
};

export async function updateCTRForAdobeAnswersAnalytics(showAdobeAnswers) {
    const attributes = {};
    const contactId = getCurrentConvID();
    // const { showAdobeAnswers } = store.getState().contactCenter;
    const activeAndCurrentConversation = fetchActiveAndCurrentConversationData(contactId);
    const { jcAuthData } = activeAndCurrentConversation || {};
    const {
        ADOBE_ANSWERS_ELIGIBLE: adobeAnswersEligible,
    } = jcAuthData || {};

    if (!activeAndCurrentConversation) return;

    // Check whether the conversation Id is already there in the each property ? if so don't update the record
    const isAdobeAnswersEligibleFlagUpdated = adobeAnswersEligible?.value?.includes(contactId) || false;

    if (!isAdobeAnswersEligibleFlagUpdated) {
        attributes['ADOBE_ANSWERS_ELIGIBLE'] = adobeAnswersEligible
            ? (`${adobeAnswersEligible.value}; ${contactId}: ${showAdobeAnswers}`)
            : (`${contactId}: ${showAdobeAnswers}`);
    }

    try {
        await updateContactAttributes({
            attributes,
            contactId
        });
    } catch (e) {
        console.log("Failed to update CTR on updateCTRForAdobeAnswersAnalytics: ", e);
    }
};
