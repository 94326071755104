import { LibraryModule as CIPLibrary } from 'oc-cip-module-client'
import 'oc-cip-module-client/dist/index.css'
import { Profiler, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { default as env, default as env_variables } from '../../../../config/env_variables'
import { onRenderCB } from '../../../../helpers/widget-performance-analytics'
import { addMessage, getClosedConversationSummary, setOutBoundCall, updateConversationSummaryFeedback } from '../../../../redux/actions/contactCenterActions'
import { CLICK_PHONE_NUMBER, CONVERSATION_SUMMARY_FEEDBACK, CHANGE_CUSTOMER } from '../../../../redux/constants/changeCustomerConstants'
import { RootState } from '../../../../redux/reducers/rootReducer'
import { getTranslationData } from '../../../ContactCenter/Api/api'
import { ContactRecord } from '../../../ContactCenter/Models'
import ContactCenter from '../../../ContactCenter/Modules/ContactCenter'
import { useAllowedAgent, useContextData } from '../../Hooks'
import './customerInfoPanel.scss'

declare const window: any

interface Prop {
  CIP: boolean
}
interface State {
  widgetLayout: {
    widgets: { title: string; name: string; active: boolean }[]
    widgetStack: Record<string, any>
  }
  preferences: {
    darkMode: boolean
    language: string
  }
  contactCenter: {
    currentConversation: ContactRecord
    currentConversationAC: ContactRecord
    currentView: string
  }
}
interface URLS {
  HENDRIX_DOMAIN: string
  CM_DOMAIN: string
  CIP_DOMAIN: string
  CCP_API_END_POINT: string
}

interface Message {
  message: string
  senderName: string
  sentBy: string
  timeStamp: string
}

const CustomerInfoPanel = ({ CIP }: Prop) => {
  const dispatch = useDispatch()
  const { darkMode, language } = useSelector((state: State) => state.preferences)

  const [showSummary, setshowSummary] = useState(false)
  const { conversationSummaries, currentConversationAC, currentConversation, currentView, transferInProgressId, toggleTranslationforCS } = useSelector(
    (state: RootState) => state.contactCenter
  )
  const conversation = currentView === 'AC_VIEW' ? currentConversationAC : currentConversation
  const { ocPlatformData: { chatInfo: { initialContactId = '', currentContactId = '', status: chatStatus = 'Active' } = {} } = {} } = conversation

  const [conversationSummary, setConversatioSummary] = useState(conversationSummaries?.[currentContactId]?.summary)
  const [agentPath, setAgentPath] = useState(conversationSummaries?.[currentContactId]?.path)
  const [feedback, setFeedback] = useState(conversationSummaries?.[currentContactId]?.feedback)
  const { jcAuthData: { language: { value: conversationLanguage = '' } = {} } = {} } = conversation

  function callBackEventListener(eventType: string, eventData: any): void {
    console.log(`Received Event from Library : eventData =  ${JSON.stringify(eventData)}`)
    if (eventType === 'OFFER_PITCH_MESSAGE') {
      const data: any = {
        ParticipantRole: 'AGENT',
        translationText: eventData?.translateMsg
      }
      dispatch(addMessage(data))
      ContactCenter.sendMessage(conversation?.ocPlatformData.chatInfo?.currentContactId, eventData)
    } else if (eventType === 'CIP_PHONE_NUMBER_CLICK' && eventData) {
      dispatch({ type: CLICK_PHONE_NUMBER, payload: eventData })
      if (!chatStatus || chatStatus === 'Closed') dispatch(setOutBoundCall(true))
    } else if (eventType === CONVERSATION_SUMMARY_FEEDBACK) {
      setFeedback(eventData)
      const data = { contactId: currentContactId, feedback: eventData, summary: conversationSummary, path: agentPath }
      dispatch(updateConversationSummaryFeedback(data))
    } else if (eventType === 'CIP_REPHRESH') {
      updateConversationSummaryInfo()
    } else if (eventType === 'UPDATE_CUSTOMER_INFO') {
      dispatch({ type: CHANGE_CUSTOMER, payload: eventData })
      console.log('SCIP Module: Dispatching change customer action with payload', eventData)
    }
  }

  const { jcAuthData, ocPlatformData, ocAppData, jcMidConvData } = useContextData()
  const contextData = { jcAuthData, ocPlatformData, ocAppData, jcMidConvData }
  const { currentQueue = '' } = contextData.jcAuthData
  const isCIP = currentQueue.substring(0, 4) === 'SLS-' ? false : true
  const label = CIP ? 'CIP' : 'SCIP'
  const allowedAgent: any = useAllowedAgent(label)
  const { isSalesAgent = false } = allowedAgent
  contextData.ocPlatformData.allowedAgent = isSalesAgent
  contextData.ocPlatformData.transferInProgressId = transferInProgressId
  const origin = contextData.ocAppData.originForCurrentConversation
  const isChat = origin === 'inbound-chat' || origin === 'chat-to-phone'

  const urls: URLS = {
    HENDRIX_DOMAIN: env.HENDRIX_DOMAIN,
    CM_DOMAIN: env.CM_DOMAIN,
    CIP_DOMAIN: env.CIP_DOMAIN,
    CCP_API_END_POINT: env.CCP_API_END_POINT
  }

  const getFastFollowFlags = () => {
    try {
      const localData: any = localStorage.getItem('fastFollowFlags')
      return JSON.parse(localData)?.FAST_FOLLOWS_GENAI_CONV_SUMMARY === true
    } catch (e) {
      console.log('fastFollowFlags fail', e)
      return false
    }
  }

  const updateConversationSummaryInfo = async () => {
    if (isChat && chatStatus === 'Closed' && !conversationSummaries?.[initialContactId]?.final) {
      await dispatch(getClosedConversationSummary(initialContactId, currentQueue))
    }
    //in case chat was transferred and page was reloaded
    //it is needed to recover summary of current conversation to display it
    if (isChat && chatStatus !== 'Closed' && !conversationSummaries?.[currentContactId]) {
      await dispatch(getClosedConversationSummary(currentContactId, currentQueue))
    }
  }

  useEffect(() => {
    updateConversationSummaryInfo()
  }, [currentContactId])

  useEffect(() => {
    const textsData = []
    const translationMap = {}
    const currentId = chatStatus === 'Closed' ? initialContactId : currentContactId
    const currentConversationSummary = conversationSummaries?.[currentId]?.summary ?? ''

    if (toggleTranslationforCS && currentConversationSummary) {
      const { main_issue = '', conversation_points = [], links } = currentConversationSummary
      console.log('CS: TRANSLATE NOW source language: ', conversationLanguage, 'Target language ', language)
      translationMap[uuidv4()] = {
        category: 'main_issue',
        preTranslationText: main_issue,
        postTranslationText: ''
      }
      conversation_points.forEach((point: any) => {
        translationMap[uuidv4()] = {
          category: 'conversation_points',
          preTranslationText: point,
          postTranslationText: ''
        }
      })

      Object.entries(translationMap).forEach(([key, value]: any) => {
        textsData.push({
          id: key,
          text: value.preTranslationText,
          doNotTranslate: false
        })
      })

      const fetchData = async () => {
        const request = {
          endpoint: env_variables.CCP_API_END_POINT,
          body: {
            sourceLanguage: conversationLanguage,
            targetLanguage: language,
            texts: textsData
          }
        }
        let customPanelAccessToken = ''
        if (localStorage.getItem('customPanelAccessToken')) {
          customPanelAccessToken = JSON.parse(localStorage.getItem('customPanelAccessToken') || '')
        }

        const headers = {
          Authorization: customPanelAccessToken,
          'x-api-key': 'oac-custom-panel'
        }

        const resp: any = await getTranslationData(request, headers)
        const {
          data: { texts: result = [] }
        } = resp?.data || {}

        result.forEach((element: any) => {
          const id = element.id
          const text = element.text
          translationMap[id].postTranslationText = text
        })

        //Update main_issue and conversation_points
        const updatedSummaryData = {
          main_issue: Object.values(translationMap)
            .filter((item: any) => item.category === 'main_issue')
            .map((item: any) => item.postTranslationText)
            .join(' '),
          conversation_points: Object.values(translationMap)
            .filter((item: any) => item.category === 'conversation_points')
            .map((item: any) => item.postTranslationText)
        }
        setConversatioSummary({ ...updatedSummaryData, links })
      }
      fetchData()
    } else {
      const currentConversationSummary = conversationSummaries?.[currentId]?.summary ?? undefined
      setConversatioSummary(currentConversationSummary)
    }
  }, [toggleTranslationforCS])

  useEffect(() => {
    let currentConversationSummary = undefined
    let fastFollowsGenAI = getFastFollowFlags()
    let currentId = ''
    try {
      currentId = chatStatus === 'Closed' ? initialContactId : currentContactId
      currentConversationSummary = conversationSummaries?.[currentId]?.summary ?? undefined
      setConversatioSummary(currentConversationSummary)
      const currentAgentPath = conversationSummaries?.[currentId]?.path ?? ''
      setAgentPath(currentAgentPath)
      const currentFeedback = conversationSummaries?.[currentId]?.feedback ?? undefined
      setFeedback(currentFeedback)
    } catch (e) {
      console.log('Error: ', e)
      setConversatioSummary(null)
      setAgentPath('')
      setshowSummary(false)
    }
    if (fastFollowsGenAI && isChat && currentConversationSummary) {
      setshowSummary(true)
    } else {
      setshowSummary(false)
    }
  }, [currentContactId, conversationSummaries])

  return window.enable_widget_analytics ? (
    <Profiler id={label} onRender={onRenderCB}>
      <CIPLibrary
        contextData={contextData}
        feedback={feedback}
        conversationSummary={conversationSummary}
        agentPath={agentPath}
        enableSummary={showSummary}
        callBackEvent={callBackEventListener}
        darkMode={darkMode}
        CIP={isCIP}
        language={language}
        urls={urls}
      />
    </Profiler>
  ) : (
    <CIPLibrary
      contextData={contextData}
      feedback={feedback}
      conversationSummary={conversationSummary}
      agentPath={agentPath}
      enableSummary={showSummary}
      callBackEvent={callBackEventListener}
      darkMode={darkMode}
      CIP={isCIP}
      language={language}
      urls={urls}
    />
  )
}

export default CustomerInfoPanel
