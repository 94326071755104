import ContactCenter from '../../Apps/ContactCenter/Modules/ContactCenter'
import { updateCTRForAdobeAnswersAnalytics } from '../../helpers/ctr-updates';

export const checkAdobeAnswerEligibility = (queue: any) => (dispatch: any) => {
  let fastFollowsAdobeAnswers = false
  try {
    fastFollowsAdobeAnswers = JSON.parse(localStorage.getItem('fastFollowFlags'))?.FAST_FOLLOWS_GENAI_ADOBE_ANSWERS === true
  } catch (e) {
    console.log('Error: Parsing localStorage for FAST_FOLLOWS_GENAI_ADOBE_ANSWERS', e)
  }

  if (fastFollowsAdobeAnswers) {
    ContactCenter.getAdobeAnswersSkills()
      .then(async (skills) => {
        const skillsLowerCase = skills.map((skill) => skill.toLowerCase())
        const shouldShowAA = skillsLowerCase.includes(queue.toLowerCase())
        dispatch({ type: 'SET_ADOBE_ANSWERS_ELIGIBLE', payload: shouldShowAA })
        await updateCTRForAdobeAnswersAnalytics(shouldShowAA)
        console.log('SET_ADOBE_ANSWERS_ELIGIBLE:  ', shouldShowAA)
      })
      .catch((err) => {
        console.log('Error fetching Adobe Answers skills from Floodgate: ', err)
      })
  }
}
