import { CHANGE_CUSTOMER, CLICK_PHONE_NUMBER } from '../constants/changeCustomerConstants'

export interface defaultStateInterface {
  selectedCustomer: any
  phoneNumber: number
}
const phoneNumber = 0
const defaultState: defaultStateInterface = {
  selectedCustomer: {
    rengaId: '',
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    contactId: ''
  },
  phoneNumber
}

const changeCustomerReducer = (state: defaultStateInterface = defaultState, action: any): defaultStateInterface => {
  switch (action.type) {
    case CHANGE_CUSTOMER:
      const selectedCustomer = {
        rengaId: action.payload.rengaId,
        email: action.payload.email,
        phone: action.payload.phone,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        contactId: action.payload.contactId
      }
      return { ...state, selectedCustomer }
    case CLICK_PHONE_NUMBER:
      console.log('CIP NEW NUMBER', state, action)
      return { ...state, phoneNumber: action.payload }
    default:
      return state
  }
}

export default changeCustomerReducer
