// @ts-nocheck
import React, { useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CaseManagement from '../Modules/CaseManagement/CaseManagement'
import CustomerInfoPanel from '../Modules/CustomerInfoPanel/CustomerInfoPanel'
import ConversationHistory from '../Modules/ConversationHistory/ConversationHistory'
import AdobeAnswersPanel from '../Modules/AdobeAnswersPanel/AdobeAnswersPanel'
import noWidget from '../../../Theme/Styles/Icons/no-widget.svg'
import EnterpriseCaseManagement from '../Modules/EnterpriseCaseManagement/EnterpriseCaseManagement'
import { handleScreenResize } from '../../../redux/actions/widgetLayoutAction'
import localization from './lang/localization'
import PredefinedContent from '../Modules/PredefinedContent'

interface State {
  widgetLayout: {
    widgets: {
      title: string
      name: string
      active: boolean
      position: number
      width: number
    }[]
    widgetStack: string[]
    widgetWrapperWidth: number
  }
  preferences: {
    darkMode: boolean
    language: string
  }
}

const WidgetContainer = () => {
  // Stack widget based on the Width of the screen
  const dispatch = useDispatch()
  const { widgets, widgetStack } = useSelector((state: State) => state.widgetLayout)
  const { darkMode, language } = useSelector((state: State) => state.preferences)
  const WidgetItemsWrapper: any = useRef(null)
  const updateDimensions = useCallback(() => {
    dispatch(handleScreenResize(widgets, widgetStack, WidgetItemsWrapper.current.clientWidth))
    console.log('WidgetItemsWrapper from container', WidgetItemsWrapper.current.clientWidth)
  }, [WidgetItemsWrapper, dispatch])

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    dispatch(handleScreenResize(widgets, widgetStack, WidgetItemsWrapper.current.clientWidth))
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [updateDimensions, widgets])

  console.log(
    'activeWidget from Container',
    useSelector((state: State) => state.widgetLayout)
  )

  const widgetComponents: any = {
    PDC: <PredefinedContent />,
    CI: <CustomerInfoPanel CIP />,
    CM: <CaseManagement />,
    SC: <CustomerInfoPanel CIP={false} />,
    ECM: <EnterpriseCaseManagement />,
    CH: <ConversationHistory />,
    AA: <AdobeAnswersPanel />,
  }

  let activeWidget = 0
  // WidgetItems: number = 0;
widgets.forEach((widget) => (widget.active ? activeWidget++ : null)) // NO SONAR
  const emptyWidget = localization[language].WIDGET_CONTAINER.EMPTY_WIDGET
  let isWidgetActiveClass = activeWidget > 0 ? 'widget-container' : 'no-widget-container'

  isWidgetActiveClass = darkMode ? `${isWidgetActiveClass} widget-container-dark` : `${isWidgetActiveClass} widget-container-light`
  return (
    <div id='widgetWrapper' ref={WidgetItemsWrapper} className={isWidgetActiveClass}>
      {activeWidget > 0 ? (
        widgets.map((w, i) =>
          w.active ? (
            <div key={`${w.title}`} className={`widget-items ${w.title}-widget`} style={{ width: `${w.width}px` }}>
              {widgetComponents[w.title]}
            </div>
          ) : null
        )
      ) : (
        <div className='no-widget'>
          <img src={noWidget} alt='Add widgets' />
          <p style={{ maxWidth: '122px' }}>{emptyWidget}</p>
        </div>
      )}
    </div>
  )
}

export default WidgetContainer
